import { PanelPlugin } from '@grafana/data';
import { DrillingBitOptions } from './types';
// import { SimplePanel } from './SimplePanel';
import { DrillBitPanel } from './DrillBitPanel';

export const plugin = new PanelPlugin<DrillingBitOptions>(DrillBitPanel).setPanelOptions(builder => {
  return builder
    .addNumberInput({
      path: 'offBottomOffset',
      defaultValue: 0.5,
      name: 'Off Bottom Offset',
    })
    .addNumberInput({
      path: 'rpmThreshold',
      defaultValue: 0,
      name: 'RPM Threshold',
    })
    .addBooleanSwitch({
      path: 'checkIsReamingWhileDrilling',
      name: 'Check Is Reaming While Drilling',
      description: 'Check Is Reaming While Drilling',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'sliding',
      name: 'Sliding',
      description: 'Sliding',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showActivity',
      name: 'Show Activity',
      description: 'Show Activity',
      defaultValue: false,
    })
    .addTextInput({
      path: 'label',
      name: 'Label',
      defaultValue: '',
    });
})
.setNoPadding()
;
