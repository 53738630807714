export enum DrillingStatus {
  Unknown = 1,
  OffBottom = 2,
  Sliding = 4,
  Rotating = 8,
  Drilling = 16,
  OnBottom = 32,
  Reaming = 64,
}

export enum Activity {
  // AC_Undefined_Status = 0,
  AC_Undefined_Status = 0,
  AC_Drilling = 2,
  AC_Connection_Drilling = 3,
  AC_Reaming = 4,
  AC_Circulate = 7,
  AC_Tripping_In = 8,
  AC_Tripping_Out = 9,
}

export function getDrillingStatus(
  holeDepth: number,
  bitDepth: number,
  rop: number,
  rpm: number,
  sliding: boolean | undefined,
  offBottomOffset: number,
  rpmThresholdValue: number,
  checkIsReamingWhileDrilling: boolean
): DrillingStatus {
  if (bitDepth === 0 && holeDepth === 0 && rop === 0) {
    return DrillingStatus.Unknown;
  }
  //off bottom
  else if (holeDepth - bitDepth > offBottomOffset) {
    return rpm > rpmThresholdValue ? DrillingStatus.Reaming : DrillingStatus.OffBottom;
  }
  //on bottom and rop is greater than 0, rotating
  else if (rop > 0) {
    let status = DrillingStatus.Drilling;
    // if (sliding === true) {
    //   status = DrillingStatus.Sliding;
    // } else {
    //   status = DrillingStatus.Rotating;
    // }
    // if (checkIsReamingWhileDrilling) {
    //   if (rpm > rpmThresholdValue) {
    //     status = status | DrillingStatus.Reaming;
    //   }
    // }
    return status;
  } else {
    return DrillingStatus.OnBottom;
  }
}
