import React from 'react';
import { Field, PanelProps, reduceField } from '@grafana/data';
import { DrillingBitOptions } from './types';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';
import { DrillingStatus, getDrillingStatus, Activity } from './witsml/DrillingStatus';
// import offBottom from './img/drilling.gif';
// const drilling = require('./img/drilling.gif');
// const offBottom = require('./img/drillbit_offBottom.gif');
// const onBottom = require('./img/drillbit_onBottom.gif');
const animationImages: { [key: string]: any } = {
  Drilling: require('./img/Drilling.gif'),
  OffBottom: require('./img/OffBottom.gif'),
  OnBottom: require('./img/OnBottom.gif'),
  Unknown: require('./img/Unknown.gif'),
};

interface Props extends PanelProps<DrillingBitOptions> {}

export const DrillBitPanel: React.FC<Props> = ({ options, data, width, height }) => {
  // const theme = useTheme();
  //TODO, use effect
  const styles = getStyles();
  if (data.series[0].fields.length < 4) {
    return <div>must configure 4 fields HoleDepth, BitDepth,Rop, WOB and Activity in the same order</div>;
  }
  let time = data.series[0].fields[0];
  let hDepth = data.series[0].fields[1];
  let bDepth = data.series[0].fields[2];
  let rop = data.series[0].fields[3];
  //   let wob = data.series[0].fields[4];
  let rpm = data.series[0].fields[5];
  let activity = data.series[0].fields.length >= 7 ? data.series[0].fields[6] : undefined;
  if (time.values.length === 0) {
    return <div>loading...</div>;
  }
  // const last = (f: Field) => f.values.get(f.values.length - 1);
  const last = (field: Field) => reduceField({field,reducers:['last']}).last;
  const lastFormated = (f: Field) => {
    return last(f) + " " + (f.display ? f.display(0).suffix?.trim() : f.config.unit);
  }

  let activityAnimation = getDrillingStatus(
    last(hDepth),
    last(bDepth),
    last(rop),
    last(rpm),
    // options.sliding,
    false,
    options.offBottomOffset,
    options.rpmThreshold,
    false
  );
  // console.log([last(hDepth), last(bDepth), last(rop), last(rpm)]);
  // console.log('[last(hDepth), last(bDepth), last(rop), last(rpm)]');
  //   let txt = activityAnimation.toString();
  let activityAnimation2: DrillingStatus = activityAnimation;
  switch (activityAnimation) {
    case DrillingStatus.Drilling:
    case DrillingStatus.Rotating:
      activityAnimation2 = DrillingStatus.Drilling;
      break;
    case DrillingStatus.Reaming:
    case DrillingStatus.OffBottom:
      activityAnimation2 = DrillingStatus.OffBottom;
      break;
    case DrillingStatus.Sliding:
    case DrillingStatus.OnBottom:
      activityAnimation2 = DrillingStatus.OnBottom;
      break;
    default:
      activityAnimation2 = DrillingStatus.Unknown;
      break;
  }

  let txt = DrillingStatus[activityAnimation2];
  let img = animationImages[txt];
  if (!img) {
    img = animationImages['Unknown'];
  }
  const LabelEl = () => {
    if (options.label !== '') {
      return <div className={styles.label}>{options.label} </div>;
    } else {
      return <></>;
    }
  };
  // console.log({ hDepth, bDepth, rop });
  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <LabelEl />
      {options.showActivity && activity ? (
        <div className={(styles as any)[Activity[last(activity)]] as string}>
          {Activity[last(activity)].replace('AC_', '').replace('_', ' ')}
        </div>
      ) : (
        ''
      )}
      <div className={styles.imgTitle}>{txt}</div>
      <img className={styles.img} src={img} />
      <div className={styles.textboxBottom}>
        <table>
          <tbody>
            <tr>
              <td>Hole Depth:</td>
              <td>{lastFormated(hDepth)}</td>
            </tr>
            <tr>
              <td>Bit Depth:</td>
              <td>{lastFormated(bDepth)}</td>
            </tr>
            <tr>
              <td>ROP:</td>
              <td>{lastFormated(rop)}</td>
            </tr>
          </tbody>
        </table>
        {/* <div>{'Hole Depth: ' + last(hDepth) + hDepth.config.unit}</div>
        <div>{'Bit Depth:  ' + last(bDepth) + bDepth.config.unit}</div>
        <div>{'ROP:        ' + last(rop) + rop.config.unit}</div> */}
      </div>
    </div>
    // <image

    // ></image>
    // <div
    //   className={cx(
    //     styles.wrapper,
    //     css`
    //       width: ${width}px;
    //       height: ${height}px;
    //     `
    //   )}
    // >
    //   <svg
    //     className={styles.svg}
    //     width={width}
    //     height={height}
    //     xmlns="http://www.w3.org/2000/svg"
    //     xmlnsXlink="http://www.w3.org/1999/xlink"
    //     viewBox={`-${width / 2} -${height / 2} ${width} ${height}`}
    //   >
    //     <g>
    //       <circle style={{ fill: `${theme.isLight ? theme.palette.greenBase : theme.palette.blue95}` }} r={100} />
    //     </g>
    //   </svg>

    //   <div className={styles.textBox}>
    //     {options.showSeriesCount && (
    //       <div
    //         className={css`
    //           font-size: ${theme.typography.size[options.seriesCountSize]};
    //         `}
    //       >
    //         Number of series: {data.series.length}
    //       </div>
    //     )}
    //     <div>Text option value: {options.text}</div>
    //   </div>
    // </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      background: linear-gradient(rgba(156, 154, 154, 1) 0%, rgba(235, 235, 235, 1) 64%, rgba(235, 235, 235, 1) 84%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-direction: column;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
    img: css``,
    textboxBottom: css`
      // font-size: calc(4vw + 4vh + 2vmin);
      font-size: 0.9vw;
      width:100%
      font-weight: 500;
      line-height: 1.2;
      position: relative;
      z-index: 1;
      text-align: left;
    `,
    imgTitle: css`
      font-size: 20px;
      width: 100%;
      position: relative;
      text-align: center;
    `,
    // txtActivity: css`
    //   font-size: 30px;
    //   width: 100%;
    //   position: relative;
    //   text-align: center;
    //   background-color: white;
    // `,
    AC_Drilling: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: lime;
    `,
    AC_Tripping_Out: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: pink;
    `,
    AC_Tripping_In: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: cyan;
    `,
    AC_Circulate: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: skyblue;
    `,
    AC_Connection_Drilling: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: light-yellow;
    `,
    AC_Reaming: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: Wheat;
    `,
    AC_Undefined_Status: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: white;
    `,
    label: css`
      font-size: 30px;
      width: 100%;
      position: relative;
      text-align: center;
      background-color: white;
    `,
  };
});
